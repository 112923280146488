// projectData.js
const projectData = [
  {
    id: 0,
    title: 'GPTing Papers',
    date: 'MAY 2024',
    venue: '',
    links: {
      paperlink:"",
      pg: "",
      codelink: "",
      demolink: "",
      blogpostlink: "/computational_models",
      video: "",
      img_src: "https://media2.giphy.com/media/5dYqPVcoq9mKuxleyR/200w.gif?cid=6c09b952f8f607ppa5nvos1pww9bbzswakr03af12aaw9eyx&ep=v1_gifs_search&rid=200w.gif&ct=g",
      demo: '',
      source: '',
    },
  },
    // {
    //     id: 0,
    //     title: 'Doggo',
    //     date: 'MAY 2024',
    //     venue: '',
    //     links: {
    //       paperlink:"",
    //       pg: "",
    //       codelink: "",
    //       demolink: "",
    //       blogpostlink: "",
    //       video: "",
    //       img_src: "./images/dog2.jpg",
    //       demo: '',
    //       source: '',
    //     },
    //   },
    {
      id: 0,
      title: '3DALL-E is patented! ',
      authors: ' Patent US20240104275A1 Inventors: Vivian Liu, Jo Vermeulen, George Fitzmaurice, Justin Matejka',
      date: 'APRIL 2024',
      venue: ' I wrote in my NSF Graduate Research Fellowship application that I would like to be an inventor. :) It happened!',
      links: {
        paperlink:"https://patents.google.com/patent/US20240104275A1/en",
        pg: "",
        codelink: "",
        demolink: "",
        blogpostlink: "",
        video: "",
        img_src: "./images/patent.png",
        demo: '',
        source: '',
      },
    },
      {
        id: 1,
        title: '3DALL-E tech transferred into plugin for Fusion 360!',
        authors: 'Autodesk Research',
        date: 'APRIL 2024',
        venue: "I am deeply grateful to Jo, George, Justin, and other collaborators at Autodesk who pushed my intern project forward to into product. The first thing I learned in undergrad besides coding was CAD, so this is a huge highlights of my PhD--to come full circle. Will post a more in-depth blog post later. :)  ",
        links: {
          paperlink:"",
          pg: "",
          codelink: "",
          blogpostlink: "",
          demolink: "https://apps.autodesk.com/FUSION/en/Detail/Index?id=8558451727109537930",
          video: "",
          img_src: "./images/butterfly.png",
          demo: '',
          source: '',
        },
      },
      {
        id: 2,
        title: 'Finished internship with Adobe Research.',
        description: 'Description for Project 2.',
        authors: 'Adobe Research',
        date: 'APRIL 2024',
        venue: 'I am sincerely thankful for the opportunity I had to work at Adobe and for all the mentorship and help I received from Rubaiat, Li-Yi, Matt, Tim, Seth, Valentina, Wil and many others. :) I spent 10 months at Adobe, first in Seattle and then in NYC.',
        links: {
          paperlink: "",
          pg: "/logomotion",
          codelink: "",
          demolink:"",
          blogpostlink: "",
          video: "",
          img_src: "./images/sunset.png",
          source: '',
        },
      },
    //   {
    //     id: 3,
    //     title: '',
    //     description: '',
    //     authors: '',
    //     venue: 'I will intern again in the fall at Google PAIR. :) ',
    //     date: 'APRIL 2024',
    //     links: {
    //       pg:"",
    //       img_src: "",
    //       demolink:"",
    //       codelink:"",
    //       video: "",
    //       paperlink: "",
    //     },
    //   }
    ];
    
    export default projectData;